<template>
	<v-container fluid>
		<v-form
			ref="passwordForm"
			v-model.trim="formValid"
			lazy-validation
			@submit.stop.prevent="onSubmit"
		>
			<v-row class="px-3 mt-3">
				<v-col md="3" lg="3" xl="2" class="pb-2">
					<label class="input-label fw-600">Claim Category</label>
				</v-col>
				<v-col md="3" lg="3" xl="2" class="pb-2">
					<label class="input-label fw-600"> Max Amount</label>
				</v-col>
				<v-col md="3" lg="3" xl="2" class="pb-2">
					<label class="input-label fw-600"> Entitle</label>
				</v-col>
				<v-col md="3" lg="3" xl="2" class="pb-2">
					<label class="input-label required fw-600"> Interval</label>
				</v-col>
				<!-- <v-col md="2" class="pb-2">
					<label class="input-label"> Taken</label>
				</v-col>
				<v-col md="2" class="pb-2">
					<label class="input-label"> Balance</label>
				</v-col> -->
			</v-row>

			<div class="px-3">
				<v-row class="pb-2" v-for="(type, index) in claimList" :key="index">
					<v-col md="3" lg="3" xl="2">
						<!-- <v-text-field
							v-model.trim="type.category"
							outlined
							placeholder="Claim Category"
							hide-details
							readonly
						></v-text-field> -->
						<span>{{ type.category }}</span>
					</v-col>
					<v-col md="3" lg="3" xl="2">
						<!-- <v-text-field
							v-model.trim="type.maxAmount"
							outlined
							placeholder="Max Day"
							readonly
							hide-details
						></v-text-field> -->
						<span>{{ formatMoney(type.maxAmount) }}</span
						>/<em class="fw-600 text-capitalize text-muted">{{
							Boolean(+type.category_duration + 1) ? "" : type.category_duration
						}}</em>
					</v-col>
					<v-col md="3" lg="3" xl="2">
						<v-text-field
							v-model.trim="type.allocated_amount"
							type="number"
							:readonly="!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin'])"
							min="0"
							@keyup="addminvalue(type)"
							@change="updateEnti(type)"
							max="999"
							outlined
							placeholder="Allocated Amount"
							hide-details
						></v-text-field>
					</v-col>
					<v-col md="3" lg="3" xl="2">
						<v-autocomplete
							:items="intervals"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							clearable
							height="30px"
							hide-details
							v-model="type.duration_type"
							item-text="title"
							item-value="value"
							placeholder="Select Interval"
							content-class="rounded-lg"
							:rules="[vrules.required(type.duration_type, `Interval[${index}]`)]"
							:class="{ required: !type.duration_type }"
						>
							<template #item="{ item }">
								<div class="text-uppercase pt-1 fw-500">
									{{ item.title }}
									<small class="text-lowercase text-muted fw-500">
										<em> ({{ item.value == "monthly" ? "every month" : "every 12 month" }}) </em>
									</small>
								</div>
							</template>
						</v-autocomplete>
					</v-col>

					<!-- <v-col md="2" class="pb-2">
						<v-text-field
							outlined
							placeholder="Taken Amount"
							hide-details
							v-model.trim="type.taken"
							readonly
							type="number"
							min="0"
							v-on:keyup="addminvalue(type)"
							max="999"
						></v-text-field>
					</v-col>
	
					<v-col md="2" class="pb-2">
						<v-text-field
							outlined
							placeholder="Balance Amount"
							hide-details
							v-model.trim="type.balance"
							readonly
							type="number"
							min="0"
							v-on:keyup="addminvalue(type)"
							max="999"
						></v-text-field>
					</v-col> -->
				</v-row>
				<v-row>
					<v-col class="" md="11">
						<v-btn
							:disabled="!formValid"
							:loading="formLoading"
							@click="onSubmit"
							depressed
							tile
							class="white--text"
							color="blue darken-4"
						>
							Submit
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</v-form>
	</v-container>
</template>

<script>
//import ValidationMixin from "@/core/plugins/validation-mixin";
import { PUT, GET } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { /* map,  */ cloneDeep } from "lodash";

export default {
	props: {
		user: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	watch: {
		user: {
			handler() {
				this.getCategoryDays();
				this.getUser();
			},
		},
	},
	data() {
		return {
			maxlengthValid: false,
			claimList1: [],
			UserList: [],
			formValid: true,
			exitsDays: 0,
			KeyIndays: 0,
			formLoading: false,
			updateDays: 0,
			intervals: [
				{
					title: "Monthly",
					value: "monthly",
				},
				{
					title: "Yearly",
					value: "yearly",
				},
			],
			claimList: {
				categories: [
					{
						category: null,
						maxAmount: 0,
						allocated_amount: 0,
						duration_type: null,
					},
				],
			},
		};
	},
	methods: {
		updateEnti(data) {
			let decimals = data.allocated_amount - Math.floor(data.allocated_amount);
			if (decimals > 0) {
				data.allocated_amount = Math.floor(data.allocated_amount) + 0.5;
			} else {
				data.allocated_amount = Math.floor(data.allocated_amount);
			}
		},
		getCategoryDays() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "setting/claim",
				})
				.then((data) => {
					_this.claimList1 = data;
					//  console.log(_this.claimList,'hello');
					/* _this.claimList = map(data, (row)=>{
           
              return {
                cat_maxAmount:row.maxAmount,
                category:row.category,
               
              }
            }); */
				})
				.catch((error) => {
					_this.logError(error);
					_this.goBack();
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},

		addminvalue(data) {
			this.exitsDays = data.maxAmount;
			this.KeyIndays = data.allocated_amount;

			if (this.exitsDays < this.KeyIndays) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "Entitle less than maxAmount." }]);
				this.formValid = false;
			} else {
				this.formValid = true;
			}
		},
		getUser() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "single-user-claim/" + _this.user,
				})
				.then((data) => {
					this.UserList = data;

					if (Array.isArray(data.claim_categories) && data.claim_categories.length) {
						const _claimList = data.claim_categories.map((claim) => {
							return {
								...claim,
								duration_type: claim.duration_type ? claim.duration_type : claim.category_duration,
							};
						});

						this.claimList = cloneDeep(_claimList);
					}
				})
				.catch((error) => {
					_this.logError(error);
					console.log(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},

		onSubmit() {
			const _this = this;
			if (!_this.$refs.passwordForm.validate()) {
				return false;
			}

			const _claimList = cloneDeep(this.claimList);

			const dep_claim = _claimList.filter((cl) => {
				if (cl.maxAmount < cl.allocated_amount) {
					return cl;
				}
			});

			if (dep_claim.length) {
				return false;
			}

			_this.formLoading = true;
			_this.$store
				.dispatch(PUT, {
					url: "claim-update/" + _this.user,
					data: { categories: this.claimList },
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Claim updated successfully.",
						},
					]);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
	},
	mounted() {
		const _this = this;
		_this.getUser();
		_this.getCategoryDays();
	},
};
</script>
